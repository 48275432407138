body {
  margin: 0;
  font-family: Lato;
  min-height: 100%;
  overflow-x: hidden;
  background-color: #FBFAF8;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #FBFAF8;
  z-index: 100;
  color: #3D3A40;
}

.logo {
  width: 96px;
}

.navbar {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 1.2em;
  font-weight: 300;
  background: #3D3A40;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 30px 20px -20px #00000018;
  margin: 0;
  position: relative;
}

.logo_navbar {
  width: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 30px;
  top: 17px;
}

.breadNav {
  margin-right: 55px;
  text-decoration: none;
  color: #FBFAF8;
}

.sweetNav {
  margin-right: 55px;
  text-decoration: none;
  color: #FBFAF8;
}

.savoryNav {
  margin-right: 55px;
  text-decoration: none;
  color: #FBFAF8;
}

.aboutNav {
  margin-right: 27px;
  text-decoration: none;
  color: #FBFAF8;
}

.navbar a:hover {
  text-decoration: underline;
  text-decoration-color: #4BB8DD;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.sticky {
  position: sticky;
  top: 0;
}

.header_top {
  position: relative;
}

.landing_bread_container {
  display: flex;
}

.landing_bread_img1 {
  width: 33%;
}

.landing_bread_img2 {
  width: 34%;
}

.landing_bread_img3 {
  width: 33%;
}

.slogan_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  font-size: 3em;
  line-height: 50px;
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  margin-left: 100px;
  margin-right: auto;
}

.slogan {
  color: #FBFAF8;;
  text-shadow: 2px 2px #000000;
  letter-spacing: .25rem;
  text-align: left;
  font-weight: 700;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 50px;
}

.intro_container {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.intro_summary {
  width: 90%;
  font-size: 18px;
  letter-spacing: .12rem;
  line-height: 26px;
  font-weight: 300;
}

.intro_image {
  width: 500px;
  border-radius: 10px;
}

.social {
  background-color: #3D3A40;
  padding: 50px 20px 50px 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
}

.social_text_container {
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
}

.social_text {
  color: #FBFAF8;
  width: 100%;
  font-size: 30px;
  font-weight: 400;
  text-shadow: 1px 1px #000000;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  letter-spacing: .12rem;
  line-height: 40px;
}

.social_text > a {
  color: #FBFAF8;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
  text-decoration-color: #4BB8DD;
}

.social_text > a:hover {
  text-decoration-color: #FBFAF8;
}

.social_image_container {
  position: relative;
  margin-right: auto;
  margin-left: 20px;
  margin-right: 20px;
  width: 90%;
}

.social_image_container > a {
  color: #000;
}

.social_mockup {
  width: 100%;
  min-width: 300px;
  border-radius: 10px;
}

.social_image_text {
  position: absolute;
  top: 43%;
  text-align: center;
  width: 100%;
}

.social_image_text_inner {
  text-shadow: #FFF .5px .5px;
  background-color: #FFFFFF66;
  box-shadow: 0px 0px 8px 2px #FFFFFF99;
  font-size: 2em;
  border-radius: 10px;
  padding: 5px;
}

.reviews_container {
  margin-top: 100px;
}

.reviews {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 50px;
}

.review_text {
  font-size: 18px;
  letter-spacing: .12rem;
  line-height: 26px;
  font-weight: 300;
}

.reviewer {
  font-size: 14px;
  letter-spacing: .12rem;
  font-weight: 300;
  margin-right: 20px;
}

.reviewer_stars {
  display: flex;
  align-items: center;
}

.star_icon {
  width: 18px;
}

hr {
  background-color: lightgray;
  width: 75%
}

.reviews_link {
  width: 80%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.reviews_link > a {
  color: #000;
  font-weight: 400;
  text-underline-offset: 5px;
  text-decoration-color: #4BB8DD;
}

.reviews_link > a:hover {
  text-decoration-color: #000;
}

.body_title {
  display: flex;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  justify-content: center;
  padding-top: 50px;
  text-align: center;
  font-size: 2em;
  letter-spacing: .12rem;
  font-weight: 300;
  line-height: 38px;
  color: #3D3A40;
}

.body_content_container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.body_content {
  width: 90%;
  font-size: 18px;
  letter-spacing: .12rem;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-weight: 300;
}

.image {
  border-radius: 10px;
}

.categories_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #4BB8DD;
  margin-bottom: 100px;
  padding: 40px 20px 40px 20px;
  border-radius: 10px;
  width: 90%;
}

.categories_text {
  width: 50%;
  font-size: 22px;
  letter-spacing: .12rem;
  line-height: 28px;
  font-weight: 400;
  margin-left: 30px;
}

.categories_links {
  width: 50%;
  display: grid;
  justify-content: center;
  grid-template-columns: 200px 200px;
  row-gap: 2px;
  column-gap: 8px;
  font-size: 18px;
  letter-spacing: .12rem;
  line-height: 24px;
  font-weight: 300;
  margin-left: 20px;
  margin-right: 30px;
}

.category_link_box {
  position: relative;
  border-radius: 10px;
}

.category_link_name {
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
  color: #FBFAF8;
  font-size: 34px;
  font-weight: 400;
  text-shadow: #000 0px 0px 10px;
  letter-spacing: .12rem;
  line-height: 34px;
}

.categories_image {
  width: 100%;
  border-radius: 10px;
}

.categories_image:hover {
  opacity: .7;
  transition-timing-function: ease-in-out;
  transition-duration: .2s;
}

.box1:hover > .image1 {
  opacity: .7;
}

.box2:hover > .image2 {
  opacity: .7;
}

.box3:hover > .image3 {
  opacity: .7;
}

.box4:hover > .image4 {
  opacity: .7;
}

.image {
  width: 100%;
  height: 100%;
}

.imagesContainer {
  display: grid;
  grid-template-columns: 25% 25% 25%;
  row-gap: 40px;
  column-gap: 40px;
  justify-content: center;
  margin-bottom: 100px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #3D3A40;
  color: #FBFAF8;
  font-size: 16px;
  font-weight: 400;
  text-shadow: 1px 1px #000000;
  letter-spacing: .12rem;
  line-height: 24px;
  padding: 20px;
  width: 100%;
}

.footer_title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.footer_main {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  width: 80%;
}

.footer_logistics {
  width: 280px;
}

.footer_links {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.footer_links_horizontal {
  display: flex;
}

.footer_links1 {
  display: flex;
  flex-direction: column;
}

.footer_links2 {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.footer_link {
  margin-top: 10px;
  text-decoration: none;
  color: #FBFAF8;
}

.footer_link:hover {
  text-decoration: underline;
  text-decoration-color: #4BB8DD;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.hours_weekdays {
  margin-top: 0px;
  margin-bottom: 5px;
}

.hours_weekends {
  margin-top: 0px;
  margin-bottom: 0px;
}

.address {
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  color: #FBFAF8;
}

.address:hover {
  text-decoration: underline;
  text-decoration-color: #4BB8DD;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.phone_number {
  margin-top: 0px;
  margin-bottom: 0px;
}

.copyright {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Styling for tablet */

@media only screen and (max-width: 1200px) {
  .slogan_container {
    font-size: 2.9em;
    top: 140px;
    margin-left: 80px;
    width: 800px;
  }
}

@media only screen and (max-width: 1000px) {
  .slogan_container {
    font-size: 2.5em;
    top: 80px;
    margin-left: 60px;
    width: 700px;
  }

  .slogan {
    margin-bottom: 30px;
    line-height: 45px;
  }
  
  .intro_container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .intro_summary {
    width: 100%;
  }

  .intro_image {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
  }
}

@media only screen and (max-width: 800px) {
  .slogan_container {
    font-size: 2.1em;
    top: 50px;
    margin-left: 50px;
    width: 600px;
  }

  .slogan {
    margin-bottom: 25px;
    line-height: 41px;
  }

  .categories_container {
    flex-direction: column;
  }

  .categories_text {
    width: 90%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .slogan_container {
    font-size: 1.9em;
    top: 35px;
    margin-left: 40px;
    width: 550px;
  }

  .slogan {
    margin-bottom: 20px;
    line-height: 40px;
  }
}


/* Styling for mobile */

@media only screen and (max-width: 600px) {
  div {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    min-height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 0px;
    font-size: .8em;
    background-color: #FBFAF8;
    z-index: 100;
    color: #3D3A40;
  }
  
  .logo {
    width: 64px;
  }
  
  .navbar {
    padding: 15px;
    font-size: .75em;
  }

  .logo_navbar {
    width: 25px;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 16px;
    top: 14px;
  }
  
  .breadNav {
    margin-right: 20px;
    margin-left: 20px;
    text-decoration: none;
    color: #FBFAF8;
  }
  
  .sweetNav {
    margin-right: 20px;
    text-decoration: none;
    color: #FBFAF8;
  }
  
  .savoryNav {
    margin-right: 20px;
    text-decoration: none;
    color: #FBFAF8;
  }
  
  .aboutNav {
    margin-right: 10px;
    text-decoration: none;
    color: #FBFAF8;
  }

  .landing_bread_img1 {
    width: 50%;
  }
  
  .landing_bread_img2 {
    width: 50%;
  }

  .slogan_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 330px;
    font-size: 1.5em;
    line-height: 30px;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    margin-left: 20px;
    margin-right: auto;
  }
  
  .slogan {
    letter-spacing: .25rem;
    font-weight: 700;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 28px;
  }

  .intro_container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .intro_summary {
    width: 100%;
    font-size: 15px;
    letter-spacing: .12rem;
    line-height: 22px;
    font-weight: 300;
  }
  
  .intro_image {
    width: 100%;
    margin-top: 30px;
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .social {
    background-color: #3D3A40;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 20px 0px 0px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .social_text_container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px 10px 0px 10px;
  }
  
  .social_text {
    color: #FBFAF8;
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 18px;
    font-weight: 400;
    text-shadow: 1px 1px #000000;
    letter-spacing: .12rem;
    line-height: 30px;
  }

  .social_image_container {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  
  .social_mockup {
    width: 100%;
    margin-top: 20px;
    margin-bottom: -10px;
    min-width: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .social_image_text {
    top: 35%;
  }

  .reviews_container {
    margin-top: 50px;
  }
  
  .reviews {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .review_text {
    font-size: 14px;
    letter-spacing: .12rem;
    line-height: 22px;
    font-weight: 300;
  }
  
  .reviewer {
    font-size: 10px;
    letter-spacing: .12rem;
    font-weight: 300;
    margin-right: 15px;
  }
  
  .reviewer_stars {
    display: flex;
    align-items: center;
  }
  
  .star_icon {
    width: 12px;
  }

  .reviews_link {
    width: 85%;
    margin-top: 30px;
  }

  .categories_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: #4BB8DD;
    border-radius: 10px;
    width: 90%;
    padding: 20px 0px;
  }
  
  .categories_text {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    font-size: 18px;
    letter-spacing: .12rem;
    line-height: 26px;
    font-weight: 400;
  }
  
  .categories_links {
    display: grid;
    grid-template-columns: 130px 130px;
    justify-content: center;
    row-gap: 2px;
    column-gap: 8px;
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: .12rem;
    line-height: 24px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
  }
  
  .category_link_box {
    position: relative;
    border-radius: 10px;
  }
  
  .category_link_name {
    position: absolute;
    top: 17px;
    width: 100%;
    text-align: center;
    color: #FBFAF8;
    font-size: 25px;
    font-weight: 400;
    text-shadow: 1px 1px #000000;
    letter-spacing: .12rem;
    line-height: 34px;
  }
  
  .categories_image {
    width: 100%;
    border-radius: 10px;
  }

  .body_title {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    justify-content: center;
    padding-top: 30px;
    text-align: center;
    font-size: 1.4em;
    letter-spacing: .12rem;
    font-weight: 300;
    line-height: 34px;
    color: #3D3A40;
  }
  
  .body_content_container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .body_content {
    width: 90%;
    font-size: 14px;
    letter-spacing: .12rem;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-weight: 300;
  }

  .imagesContainer {
    display: grid;
    grid-template-columns: 40% 40%;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: center;
    margin-bottom: 50px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #3D3A40;
    color: #FBFAF8;
    font-size: 12px;
    font-weight: 400;
    text-shadow: 1px 1px #000000;
    letter-spacing: .12rem;
    line-height: 22px;
    padding: 20px 10px;
    width: 100%;
  }
  
  .footer_title {
    margin-top: 15px;
    margin-bottom: 0px;
    font-weight: bold;
  }
  
  .footer_main {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 10px;
    width: 100%;
  }
  
  .footer_logistics {
    width: 200px;
  }
  
  .footer_links {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-left: 40px;
  }

  .footer_links_horizontal {
    flex-direction: column;
  }
  
  .footer_links1 {
    display: flex;
    flex-direction: column;
  }
  
  .footer_links2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
  
  .footer_link {
    margin-top: 0px;
    margin-bottom: 6px;
    text-decoration: none;
    color: #FBFAF8;
  }
}